export const cooperators = [
  {
    id: 1,
    text: "PZU",
    img: "https://www.pzu.pl/_cms-img/time20231024160141/pzu/pzu-logo.svg",
  },
  {
    id: 2,
    text: "Warta",
    img: "https://www.warta.pl/assets/images/logo-warta.svg",
  },
  {
    id: 3,
    text: "Generali",
    img: "https://www.generali.pl/media/logo_0ee6e60819_6b3c76a6ef.png",
  },
  {
    id: 4,
    text: "Allianz",
    img: "https://www.allianz-trade.com/content/dam/onemarketing/system/allianz-logo.svg",
  },
  {
    id: 5,
    text: "Link4",
    img: "https://www.link4.pl/sites/default/files/logo_main_1.png",
  },
  {
    id: 6,
    text: "Uniqa",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Uniqa_Insurance_Group_logo.svg/1200px-Uniqa_Insurance_Group_logo.svg.png",
  },
  {
    id: 7,
    text: "Proama",
    img: "https://www.proama.pl/kampanie/oc-ac-master/img/logo.png",
  },
  {
    id: 8,
    text: "Aig",
    img: "https://www.aig.pl/content/experience-fragments/aig/emea/poland/pl/header/master/_jcr_content/root/responsivegrid/container_1423107018/image.coreimg.png/1619272064755/aig-logo.png",
  },
];

export const insuranceDescription = [
  {
    id: 1,
    title: "Ubezpieczenia na życie",
    img: "zycie.jpg",
    alt: "Image by Sasin Tipchai from Pixabay",
    shortText: "Nigdy nie wiesz kiedy Twoi bliscy będą potrzebować",
    longText: [
      "Śmierć - Świadczenie, w wysokości sumy ubezpieczenia, wypłacane jest w przypadku śmierci ubezpieczonego, bez względu na jej przyczynę.",
      "Trwały uszczerbek na zdrowiu wskutek NW - Świadczenie z tytułu umowy wypłacane jest Ubezpieczonemu w przypadku wystąpienia uszczerbku na zdrowiu. Poziom wypłaty uzależniony jest od określonego procentowo trwałego uszczerbku na zdrowiu. Wypłata odbywa się zgodnie z zasadą za 1% uszczerbku wypłacane jest 1 % sumy ubezpieczenia.",
      "Uraz niepowodujący trwałego uszczerbku na zdrowiu - Świadczenie z tytułu umowy wypłacane jest w przypadku wystąpienia urazu powodującego złamanie, zwichnięcie lub skręcenie i niepowodującego jednocześnie trwałego uszczerbku na zdrowiu. Świadczenie wypłacane jest w wysokości 1% sumy ubezpieczenia.",
      "Niezdolność do pracy zarobkowej wskutek NW-Wypłata świadczenia równego sumie ubezpieczenia w przypadku wystąpienia niezdolności do wykonywania przez ubezpieczonego pracy zarobkowej. Aby świadczenie mogło być wypłacone niezdolność powinna: być skutkiem nieszczęśliwego wypadku, rozpocząć się w ochronie, trwać określony w umowie minimalny czas np 12 miesięcy i zgodnie z wiedzą medyczną nie rokować odzyskania zdolności do pracy zarobkowej w przyszłości.",
      "Pobyt w szpitalu - Świadczenie dzienne wypłacane jest za każdy dzień pobytu w szpitalu. Wysokość świadczenie wynosi określony w umowie% sumy ubezpieczenia za każdy dzień pobytu w szpitalu wskutek NW lub  w wyniku choroby. ",
      "Poważne zachorowania - Wypłata świadczenia w określonej w umowie wysokości  SU w zależności od stopnia zaawansowania np nowotworu złośliwego inwazyjnego lub  w sytuacji wystąpienia u Ubezpieczonego pozostałych chorób wymienionych w OWU każdego Towarzystwa Ubezpieczeniowego.",
      "Śmierć Współmałżonka (Partnera)",
      "Poważne zachorowania Współmałżonka (Partnera)",
      "Urodzenie dziecka",
      "Osierocenie dziecka",
      "Poważne zachorowanie dziecka",
      "Śmierć rodziców",
    ],
  },
  {
    id: 2,
    title: "Ubezpieczenia komunikacyjne",
    img: "car2.jpg",
    alt: "Obraz Ingo Leukers z Pixabay",
    shortText: "Potrzebujesz jeśli jesteś właścicielem zarejetrowanego pojazdu",
    longText: [
      "OC - ubezpieczenie OC samochodu jest obowiązkowe. Zabezpiecza przed skutkami finansowymi wynikającymi z ewentualnych szkód, wyrządzonych osobom trzecim w związku z ruchem pojazdu. Sposoby zawarcie oraz Sumy Gwarancyjne reguluje Ustawa z 22 maja 2003 roku o ubezpieczeniach obowiązkowych.",
      "OCHRONA ZNIŻEK -ubezpieczenie to gwarantuje, że w razie spowodowania przez ubezpieczone nim autem, jednej szkody OC , zniżka za bezszkodowy przebieg ubezpieczenia zostanie utrzymana, a składka za ubezpieczenie OC w kolejnym roku nie zmieni się, o ile w umowie zawieranej na kolejny rok nie nastąpiły zmiany w zakresie własności pojazdu. Trzeba pamiętać że ochrona zniżek dotyczy tylko auta które ma ją wykupione i spowodowało szkodę.",
      "NNW - NNW w Pojeździe to standardowa ochrona od następstw nieszczęśliwych wypadków dla wszystkich osób, łącznie z kierowcą, które w danej chwili podróżują ubezpieczonym pojazdem. W zależności od wybranej Sumy Ubezpieczenia oraz zapisów w OWU , kierowca i pasażerowie są chronieni nie tylko podczas korzystania z pojazdu ale również podczas wsiadania i wysiadania z pojazdu czy podczas naprawy pojazdu na trasie.",
      "ZIELONA KARTA - to ubezpieczenie gwarantujące ochronę z tytułu odpowiedzialności cywilnej posiadaczy pojazdów mechanicznych na terenie Albanii, Azerbejdżanu, Białorusi, Czarnogóry, Iranu, Maroka, Mołdawii, Macedonii, Rosji, Tunezji, Turcji, Ukrainy. W wielu Towarzystwach Ubezpieczeniowych dodawana bezpłatnie do OC.",
      "ASSISTANCE - dodatkowe ubezpieczenie na wypadek awarii, wypadku auta. Gwarantuje , w zależności od wybranego wariantu i TU szeroki zakres pomocy w trasie.",
      "AC - auto casco - obejmuje wiele wariantów, jest ubezpieczeniem dopasowanym do potrzeb i klienta. ",
    ],
  },
  {
    id: 3,
    title: "Ubezpieczenie nieruchomości",
    img: "house.jpg",
    alt: "Image by Pexels from Pixabay",
    shortText:
      "Wybierz jeśli chcesz mieć mniej stresu związanego ze stanem nieruchmości",
    longText: [
      "Mury i stałe elementy - Stałe elementy elementy mieszkania lub domu zamontowane lub wbudowane w sposób trwały tzn nie da się ich odłączyć od murów bez użycia narzędzi, np.: okna, drzwi, trwała zabudowa meblowa kuchni, wnęk, powłoki malarskie, zabudowa balkonów, obudowa instalacji, instalacje, urządzenia techniczne, telewizor zamontowany na ścianie, przyłącza mediów.",
      "Ruchomości domowe - ruchomości domowe, elementy które możemy wynieść z domu, mieszkania bez użycia narzędzi. Są to to między innymi (meble, sprzęt audiowizualny, komputerowy, fotograficzny, dywany, odzież, książki, inne przedmioty osobistego użytku, itd.)",
      "Kradzież Ruchomości Domowych -ruchomości domowe od kradzieży z włamaniem lub rabunku. Należy pamiętać że wymagane jest aby nieruchomość zabezpieczona była co najmniej jednym zamkiem wielozastawkowym lub jednym zamkiem wielopunktowym, lub mechaniczno – elektronicznym.",
      "Odpowiedzialność cywilna w życiu prywatnym - Ważne ubezpieczenie, w codziennym życiu. Ubezpieczyciel zapłaci np. za zalanie sąsiada, zbitą przez dzieci szybę, porysowane przez dzieci auto sąsiada szkody wyrządzone przez porwany w czasie burzy parasol ogrodowy lub inny przedmiot, uszkodzenia spowodowane przez nas podczas jazdy hulajnogą elektryczną, na nartach, rowerem elektrycznym, deskorolką elektryczną,rowerem.",
      "Przepięcie - gwałtowna zmiana napięcia w sieci elektrycznej.",
      "Powódź – czyli zalanie terenu w następstwie podniesienia się poziomu wody w korytach wód płynących lub stojących.",
      "Ubezpieczenie szyb i elementów szklanych - przypadkowe rozbicie lub pęknięcie zdefiniowanych w Ogólnych Warunkach Ubezpieczenia, przedmiotów szklanych.",
      "Assistance - w ramach tej usługi Towarzystwo Ubezpieczeniowe zapewni pomoc i usługi m. in. informatyka, hydraulika, ślusarza, elektryka. Trzeba pamiętać że odpowiedzialność ograniczona jest do limitów odpowiedzialności na usługę i liczby zdarzeń, wskazanych w OWU każdego Towarzystwa Ubezpieczeniowego.",
    ],
  },
  {
    id: 4,
    title: "Ubezpieczenie podróży",
    img: "travel.jpg",
    alt: "Image by Nimrod Oren from Pixabay",
    shortText: "Potrzebujesz jeśli wyjeżdzasz i chcesz odpocząć bez stresu",
    longText: [
      "Koszty leczenia - Towarzystwo Ubezpieczeniowe gwarantuje pokrycie wydatków związanych z leczeniem ambulatoryjnym, szpitalnym, stomatologicznym oraz zakupem leków i środków opatrunkowych. Ważne żeby dobrac odpowiednią Sumę Ubezpieczenia do kraju podróży.",
      "Assistance w podróży - w zależności od wybranego wariantu obejmuje między innymi pomoc w przypadku kradzieży lub utraty dokumentów, pomoc w odzyskaniu i ponownym wysłaniu bagażu, transport zwłok, transport osób towarzyszących w razie śmierci Ubezpieczonego,całodobowy dyżur telefoniczny Centrum Pomocy, pokrycie kosztów poszukiwań, pokrycie kosztów ratownictwa, pomoc w przypadku opóźnienia lotu, pomoc tłumacza itp.",
      "NNW następstwa nieszczęśliwych wypadków - Wypłata świadczenia z tytułu śmierci lub trwałego uszczerbku na zdrowiu powstałych w wyniku nieszczęśliwego wypadku podczas podróży, wypłacane w wysokości zależnej od ustalonej Sumy Ubezpieczenia.",
      "Odpowiedzialność Cywilna - Odpowiedzialność ubezpieczonego za szkody wyrządzone w życiu prywatnym osobom trzecim polegające na uszkodzeniu/zniszczeniu ich mienia lub spowodowaniu uszczerbku na zdrowiu podczas podróży krajowej lub zagranicznej w zależności od wybranego wariantu. Przydatny element polisy zwłaszcza dla narciarzy , rodzin z dziećmi na wakacjach.",
      "Bagaż podróżny - Wypłata odszkodowania za utratę, zniszczenie, uszkodzenie rzeczy osobistych przewożonych w ramach bagażu podróżnego.",
    ],
  },
  {
    id: 5,
    title: "Ubezpieczenie dla Dziecka",
    img: "child.jpg",
    alt: "Obraz bottomlayercz0 z Pixabay",
    shortText: "Twoje dziecko będzie potrzebować jeśli przydarzy się coś złego",
    longText: [
      "Ubezpieczenie zdrowotne: Zapewnia ochronę zdrowotną, pokrywając koszty leczenia, wizyt lekarskich, badań diagnostycznych, hospitalizacji i leków. Może obejmować także opiekę dentystyczną i okulistyczną.",
      "Ubezpieczenie na życie dla dziecka: Ubezpieczenie na życie zapewnia ochronę finansową w przypadku śmierci dziecka. Środki z polisy mogą być użyte na różne cele, takie jak pokrycie kosztów pogrzebu, edukacji lub inwestycji na przyszłość.",
      "Ubezpieczenie od skutków nieszczęśliwych wypadków: Oferuje ochronę w przypadku urazów wynikających z nieszczęśliwych wypadków, takich jak złamania, skręcenia czy uszkodzenia narządów.",
      "Ubezpieczenie od chorób krytycznych: Zapewnia wypłatę środków w przypadku zdiagnozowania u dziecka poważnej choroby, takiej jak rak, choroba serca czy inne choroby krytyczne.",
      "Ubezpieczenie edukacyjne: Pomaga w planowaniu finansowym na cele związane z edukacją dziecka, takie jak opłaty za szkołę, college czy inne formy kształcenia.",
      "Ubezpieczenie odpowiedzialności cywilnej: Ochrona przed sytuacjami, w których dziecko wyrządzi szkody innym osobom lub ich mieniu, co może obejmować koszty prawne i odszkodowania.",
    ],
  },
  {
    id: 6,
    title: "Pozostałe ubezpieczenia",
    img: "inne.jpg",
    alt: "Image by StockSnap from Pixabay",
    shortText: "Ubezpieczę niemal wszystkie Twoje pomysły i potrzeby ",
    longText: [
      "Auto Assistance - dla wszystkich którzy z jakiegoś powodu nie wykupili tego ubezpieczenia zawierając polisę oc lub ac a wybierają się w podróże dalsze lub bliższe. Możliwość wykupienia polisy nawet do 2500km.      ",
      "Ubezpieczenie smartfona, tabletu lub smartwatcha - w zakresie przypadkowego uszkodzenia sprzętu lub ochrony wyświetlacza. W zależności od wybranego wariantu zakres ochrony może być bardzo szeroki i obejmować między innymi przypadkowe uszkodzenie wyświetlacza, przypadkowe uszkodzenie sprzętu, kradzież z włamaniem, kradzież kieszonkowa, rabunek, transport sprzętu do i z serwisu.",
      "Ubezpieczenie dla firm",
      "Ubezpieczenie rolne.",
      "Ubezpieczenie OC Przewożnika.",
      "Ubezpieczenie maszyn i urządzeń budowlanych.",
      "Ubezpieczenie OC zawodowe.",
      "Inne w zależności od potrzeby klienta",
    ],
  },
];
